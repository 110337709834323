import { useEffect, useState } from "react";

export default function Galeria() {
    let item_section = [];
    const [menu, setMenu] = useState([]);

    useEffect(() => {
        const getMenu = async () => {
            let res = await fetch("helpers/menu.json"),
            json = await res.json();
            json.forEach(element => {
                console.log(element);
                let e = {
                    "key": element.id,
                    "url": "./assets/img/menu/" + element.name
                }
                setMenu((menuMovil) => [...menuMovil, e]);
            });
            // console.log(item_section);
        }
        getMenu();
    }, []);

    return (
        <div className="row row-cols-md-3 contentMenu px-5 mt-3">
            <img src="./assets/img/menu/MenuGarage51_page-0001.jpg" alt="" />
            <img src="./assets/img/menu/MenuGarage51_page-0002.jpg" alt="" />
            <img src="./assets/img/menu/MenuGarage51_page-0003.jpg" alt="" />
            <img src="./assets/img/menu/MenuGarage51_page-0004.jpg" alt="" />
            <img src="./assets/img/menu/MenuGarage51_page-0005.jpg" alt="" />
            <img src="./assets/img/menu/MenuGarage51_page-0006.jpg" alt="" />
            <img src="./assets/img/menu/MenuGarage51_page-0007.jpg" alt="" />
        </div>
    );
}