import React, { useEffect, useState } from "react";
import { Carousel, Image, Modal } from "react-bootstrap";
import config from '../config.json';

const ModalPrincipal = (props) => {
    const [anuncios, setAnuncios] = useState([]);

    useEffect(() => {
        const callServiceAnuncios = async () => {
            try {
                const response = await fetch(`${config.URI_SERVICE}${config.METHOD_GET_ANUNCIOS_TIPO}`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({ TIPO: "3" })
                });

                if (!response.ok) {
                    throw new Error('Failed to save');
                } else {
                    const responseData = await response.json();
                    if (responseData.operation) {
                        const newAnuncios = responseData.mensaje.map(element => ({
                            "key": element.CVE_ANUNCIO,
                            "img": element.IMAGEN,
                            "titulo": element.TITULO,
                            "desc": element.DESCRIPCION,
                            "tyc": element.TERMINOS_CONDICIONES
                        }));
                        setAnuncios(newAnuncios);
                        console.log(newAnuncios); // Aquí imprimes el estado actualizado
                    }
                }
            } catch (error) {
                console.error('Error:', error);
            }
        };

        callServiceAnuncios();
    }, []);

    return (
        <Modal {...props}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            size="md">
            <Modal.Body>
                {anuncios.length === 0 ? (
                    <div className="row row-cols-md-2 contentPromos">
                        <h1 key={0} className="title_cargando">Cargando...</h1>
                    </div>
                ) : (
                    <Carousel>
                        {anuncios.map((e) => (
                            <Carousel.Item key={e.key}>
                                <img
                                    className="d-block w-100"
                                    src={e.img}
                                    alt={e.titulo}
                                />
                            </Carousel.Item>
                        ))}
                    </Carousel>
                )}
            </Modal.Body>
        </Modal>
    );
}


export default ModalPrincipal;