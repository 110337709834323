import logo from './logo.svg';
import './App.css';
import ButtonRedes from './Components/BotonRedes';
import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom';
import "./Assets/scss/styles.scss"
import Footer from './Components/Footer';
import 'bootstrap';
import NavbarG51 from './Components/NavbarG51';
import Promociones from './Components/Promociones';
import Home from './Components/Home';
import Menu from './Components/Menu';
import Sucursales from './Components/Sucursales';
import AdminLogin from './Components/AdminLogin';
import ConsoleAdmin from './Components/ConsoleAdmin';
import NavbarG51ConsoleAdmin from './Components/NavbarG51ConsoleAdmin';
import AdminPromociones from './Components/AdminPromociones';
import TerminosCondiciones from './Components/TerminosCondiciones';
import AvisoPrivacidad from './Components/AvisoPrivacidad';
import Historia from './Components/Historia';
import QuienesSomos from './Components/QuienesSomos';
const validPaths = [
  '/',
  '/Promociones',
  '/menu',
  '/Sucursales',
  '/terminos_condiciones',
  '/aviso_privacidad',
  '/historia',
  '/quienes_somos'
];
const fullPaths = [
  '/',
  '/Promociones',
  '/menu',
  '/Sucursales',
  '/terminos_condiciones',
  '/aviso_privacidad',
  '/historia',
  '/quienes_somos',
  '/AdminLogin',
  '/cosole_admin',
  '/admin_promos'
];
function Navbar() {
  const location = useLocation();
  if (!fullPaths.includes(location.pathname)) {
    return null;
  }
  //console.log("location", location);
  const showNavbarG51 = validPaths.includes(location.pathname);
  //const showNavbarG51 = location.pathname === '/' || location.pathname.startsWith('/Promociones') || location.pathname.startsWith('/menu') || location.pathname.startsWith('/Sucursales') || location.pathname.startsWith('/terminos_condiciones') || location.pathname.startsWith('/aviso_privacidad') || location.pathname.startsWith('/historia') || location.pathname.startsWith('/quienes_somos');
  //console.log("locationShow", showNavbarG51);
  return showNavbarG51 ? <NavbarG51 /> : <NavbarG51ConsoleAdmin />;
}

function FooterShow() {
  const location = useLocation();
  if (!fullPaths.includes(location.pathname)) {
    return null;
  }
  //console.log("location", location);
  const showFooterG51 = validPaths.includes(location.pathname);
  //const showFooterG51 = location.pathname === '/' || location.pathname.startsWith('/Promociones') || location.pathname.startsWith('/menu') || location.pathname.startsWith('/Sucursales') || location.pathname.startsWith('/terminos_condiciones') || location.pathname.startsWith('/aviso_privacidad') || location.pathname.startsWith('/historia') || location.pathname.startsWith('/quienes_somos');
  //console.log("locationShow", showFooterG51);
  return showFooterG51 ? <Footer /> : null;
}

function App() {
  return (
    <BrowserRouter>
      <Navbar />
      <Routes>
        <Route index path="/" element={<Home></Home>}></Route>
        <Route path="/Menu" element={<Menu></Menu>}></Route>
        <Route path="/Promociones" element={<Promociones></Promociones>}></Route>
        <Route path="/Sucursales" element={<Sucursales></Sucursales>}></Route>
        <Route path="/AdminLogin" element={<AdminLogin></AdminLogin>}></Route>
        <Route path="/cosole_admin" element={<ConsoleAdmin></ConsoleAdmin>}></Route>
        <Route path="/admin_promos" element={<AdminPromociones></AdminPromociones>}></Route>
        <Route path="/terminos_condiciones" element={<TerminosCondiciones></TerminosCondiciones>}></Route>
        <Route path="/aviso_privacidad" element={<AvisoPrivacidad></AvisoPrivacidad>}></Route>
        <Route path="/historia" element={<Historia></Historia>}></Route>
        <Route path="/quienes_somos" element={<QuienesSomos></QuienesSomos>}></Route>
      </Routes>
      <FooterShow />
      {/* <MessengerCustomerChat
         pageId="1781965312042121"
         appId="529260114782520"
        // htmlRef="<REF_STRING>"
      /> */}
    </BrowserRouter>
  );
}

export default App;
