import { useEffect, useState } from "react";
import { Carousel, Container, Image, Modal } from "react-bootstrap";
import config from '../config.json';
import logo from '../Assets/img/1.svg';

export default function CarruselG51() {
    const [slides, setSlides] = useState([]);
    const [modalShowViewImg, setModalShowViewImg] = useState(false);
    const [base64Image, setbase64Image] = useState("");
    function popupImage(src) {
        setbase64Image(src);
        setModalShowViewImg(true);
    }
    useEffect(() => {
        const callServiceCarousel = async () => {
            try {
                const response = await fetch(`${config.URI_SERVICE}${config.METHOD_GET_ANUNCIOS_TIPO}`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({ TIPO: "4" })
                });

                if (!response.ok) {
                    throw new Error('Failed to save');
                } else {
                    const responseData = await response.json();
                    if (responseData.operation) {
                        const newSlide = responseData.mensaje.map(element => ({
                            "key": element.CVE_ANUNCIO,
                            "url": element.IMAGEN,
                            "titulo": element.TITULO
                        }));
                        setSlides(newSlide);
                    }
                }
            } catch (error) {
                console.error('Error:', error);
            }
        };

        callServiceCarousel();
    }, []);
    return (
        <>
            {
                slides.length === 0 ? (
                    <div className="row row-cols-md-2 contentPromos">
                        <h1 className="title_cargando">Cargando...</h1>
                    </div>
                ) : (
                    <>
                        <img src={logo} className="logo logoCarousel" />
                        <Carousel>
                            {slides.map((e) => (
                                <Carousel.Item>
                                    <img
                                        onClick={() => popupImage(e.url)}
                                        id={e.key}
                                        className="d-block w-100"
                                        src={e.url}
                                        alt={e.titulo}
                                    />
                                </Carousel.Item>
                            ))}
                        </Carousel>
                        <Modal
                            className="ModalCarousel"
                            size="lg"
                            show={modalShowViewImg}
                            onHide={() => setModalShowViewImg(false)}
                            aria-labelledby="example-modal-sizes-title-lg">
                            <Modal.Header closeButton>
                                <Modal.Title id="example-modal-sizes-title-lg">
                                    <h1>Experiencia Garage 51</h1>
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Container className="d-flex flex-row justify-content-center">
                                    <img src={logo} className="logo position-absolute top-1 start-0" />
                                    <Image src={base64Image} rounded className="imgModalCarousel" />
                                </Container>
                            </Modal.Body>
                        </Modal>
                    </>
                )
            }
        </>
    );
}