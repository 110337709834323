import { faUser, faEnvelope, faIdBadge } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Container, Nav, Navbar, NavDropdown, Button, Row } from "react-bootstrap";
import logo from '../Assets/img/1.svg';
import React, { useState, useEffect } from "react";
export default function NavbarG51ConsoleAdmin() {
    const [Session, setSession] = useState({});
    const [loggedIn, setLoggedIn] = useState(false);
    useEffect(() => {
        const sessionData = localStorage.getItem('session');
        if (sessionData) {
            const parsedSession = JSON.parse(sessionData);
            setSession(parsedSession);
            setLoggedIn(true)
        }
    }, []);
    function handleLogout(event) {
        event.preventDefault();
        localStorage.removeItem('session');
        window.location.href = '/AdminLogin';
    }
    return (
        <>
            <Navbar  style={{position: "sticky"}} collapseOnSelect expand="md" bg="dark" variant="dark" className="nav p-0 m-0" fixed="top">
                <Navbar.Brand className="d-flex flex-row justify-content-center align-items-center"> <img src={logo} className="logo"></img><h4 className="">Consola de administración</h4></Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <br />
                {loggedIn && (
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="ms-auto">
                            <Container>
                                <NavDropdown title={Session.username} id="basic-nav-dropdown" drop="start" className="align-items-end">
                                    <NavDropdown.Item><FontAwesomeIcon icon={faUser} /> {Session.nombre}</NavDropdown.Item>
                                    <NavDropdown.Item><FontAwesomeIcon icon={faEnvelope} /> {Session.email}</NavDropdown.Item>
                                    <NavDropdown.Item><FontAwesomeIcon icon={faIdBadge} /> {Session.nombre_perfil}</NavDropdown.Item>
                                    <Container>
                                        <Button variant="outline-warning" onClick={handleLogout} className="float-end" >Sign out</Button>
                                    </Container>
                                </NavDropdown>
                            </Container>
                        </Nav>
                    </Navbar.Collapse>
                )}
            </Navbar>
        </>
    );
}