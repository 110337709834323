
import { faCircleArrowDown, faCircleArrowUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Fragment, useState } from "react";
import { Container, Row } from "react-bootstrap";
import banner from '../Assets/img/galeria/main.jpg'
import CarruselG51 from "./CarruselG51";
import Picometro from "./Picometro";
import ModalPrincipal from "./ModalPrincipal";
import MarqueeG51 from "./MarqueeG51";

export default function Home() {
    const [visible, setVisiable] = useState(true);
    return (
        <Fragment>
            <div className=" container-fluid ">

                <section id="sec1" className="sec1 banner row align-items-center justify-content-md-evenly justify-content-center">
                    <div className="col-12 col-md-4">
                        <h1 className="text-center text-light">Esto es Garage 51 <br /> ¡Ven a conocernos! </h1>

                    </div>
                    <div className="col-12 col-md-8">

                        <img src={banner} className="w-100 "></img>
                    </div>
                    <a href="#sec2" className="btn1 d-none d-lg-inline">
                        <FontAwesomeIcon icon={faCircleArrowDown} />
                    </a>
                </section>
                <section id="secMarquee" className="sec1 banner row align-items-center justify-content-md-evenly justify-content-center">
                 <MarqueeG51></MarqueeG51>
                </section>
                <section className="sec2 row  align-items-center justify-content-around " id="sec2">

                    <div className="col-11 col-md-5 picometro order-last order-md-first">
                        <Picometro></Picometro>
                    </div>
                    <div className="col-12 col-md-5 my-3 my-md-0">
                        <CarruselG51></CarruselG51>
                    </div>
                    <a href="#sec3" className="btn1 d-none d-lg-inline">
                        <FontAwesomeIcon icon={faCircleArrowDown} />
                    </a>
                </section>
            </div>
            <ModalPrincipal
                show={visible}
                onHide={() => setVisiable(false)}></ModalPrincipal>
        </Fragment>
    );
}