import React, { useState } from 'react';
import img_g51 from "../Assets/img/galeria/307894536_113209374868732_2358394119508060_n.jpg"
import { Col, Image, Row } from 'react-bootstrap';

export default function QuienesSomos() {
    return (
        <>
            <div className="container">
                <h1 className='mt-2 title_AP'>Quienes somos!</h1>
                <div className="mb-4 container d-flex flex-row justify-content-center align-items-center">
                    <div className='containerAP d-flex flex-row justify-content-center align-items-center'>
                        <div className="post-it-note">
                            <p className='p_ap'>Nuestra principal motivacion para crear Garage 51 fue
                                la de pasar un buen rato con tu familia y amigos, en un
                                ambiente agradable; con un concepto de Garage
                                (motos, autos, rutas y ROCK). Ademas de ofrecer la
                                mayor calidad y sabor en el mundo de los restaurantes
                                sport - casual..
                            </p>
                            <p className='p_ap'>Nos seguimos renovando para vivir de la mano de un
                                solo objetivo; crear las mejores experiencias e
                                increibles encuentros.</p>
                            <p className='p_ap'>Simplemente si amas las alitas, los spots tematicos y el
                                rock, estas en lugar indicado!!</p>
                            <div className='container-fluid d-flex flex-row justify-content-center'>
                                <Image src={img_g51} roundedCircle width={250} />
                            </div>
                            <h2>Misión</h2>
                            <p className='p_ap'>Ofrecer una experiencia única basadandonos en:
                                la calidad y sabor de nuestros alimentos, una
                                ambientacion tematica unica, buena música
                                (oldies sobre todo), y que nuestros clientes vivan
                                la experiencia G51.</p>
                            <h2>Visión</h2>
                            <p className='p_ap'>Creemos que nuestra mejor ventaja sera siempre
                                estarnos renovando. Nos inclinamos por el impacto a
                                largo plazo para asi competir contra grandes marcas
                                de comida sport-casual en Mexico, mediante el
                                crecimiento constante, la practica de nuestros
                                valores, la importancia de nuestros clientes y la
                                innovacion de nuestros productos.</p>
                            <h2>Valores</h2>
                            <Row className="d-flex flex-row justify-content-center align-items-center">
                                <Col xs={6} md={6}>
                                    <ul className='lista_ap'>
                                        <li>Inclusión</li>
                                        <li>Respeto</li>
                                        <li>Actitud de servicio</li>
                                        <li>Innovación</li>
                                    </ul>
                                </Col>
                                <Col xs={6} md={6}>
                                    <ul className='lista_ap'>
                                        <li>Calidad</li>
                                        <li>Eficiencia</li>
                                        <li>Mejora continua</li>
                                        <li>Diversión</li>
                                    </ul>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}