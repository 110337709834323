import { Fragment } from "react";
import Home from "./Home";
import SidebarG51Admin from "./SidebarG51Admin";
import { BrowserRouter, Route, Routes } from "react-router-dom";

export default function ConsoleAdmin() {
    return (
        <SidebarG51Admin>
        </SidebarG51Admin>
    );
}