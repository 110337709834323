import { Form, Tab, Tabs, InputGroup, FormControl, Button, Row, Col, Image, Modal, Table, Container } from "react-bootstrap";
import SidebarG51Admin from "./SidebarG51Admin";
import { MdOutlineViewModule, MdTitle } from "react-icons/md";
import { CiTextAlignCenter } from "react-icons/ci";
import { GoLaw } from "react-icons/go";
import { GrStatusUnknown } from "react-icons/gr";
import { FaRegEdit, FaRegTrashAlt } from "react-icons/fa";
import { useEffect, useState } from "react";
import config from '../config.json'
import "./adminpromos.css";
import imageCompression from 'browser-image-compression';

export default function AdminPromociones() {
    const [formSavePromo, setFormSavePromo] = useState({ TIPO: "1" });
    const [formConsultPromoAll, setFormConsultPromoAll] = useState({ TIPO: "1", STATUS: "1" });
    const [formEditPromo, setFormEditPromo] = useState({});
    const [tabsState, setTabState] = useState([true, false]);
    const [imgSavePromo, setImgSavePromo] = useState("");
    const [imgEditPromo, setImgEditPromo] = useState("");
    const [loading, setLoading] = useState(false);
    const [modalShow, setModalShow] = useState(false);
    const [modalShowViewImg, setModalShowViewImg] = useState(false);
    const [modalTitle, setModalTitle] = useState("");
    const [modalBody, setModalBody] = useState("");
    const [tablaDataPromos, setTablaDataPromos] = useState([]);
    const [cursorDataPromo, setCursorDataPromo] = useState(0);
    const [modalShowEdit, setModalShowEdit] = useState(false);
    const [modalShowEdMsg, setModalShowEdMsg] = useState(false);

    function handleSelect(page) {
        let position = page == "alta" ? 0 : 1;
        let state_tab = [false, false];
        state_tab[position] = true;
        setTabState(state_tab);
    };

    async function saveAnuncio(request) {
        try {
            setLoading(true);
            const response = await fetch(`${config.URI_SERVICE}${config.METHOD_SAVE_ANUNCIO}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(request)
            });

            if (!response.ok) {
                throw new Error('Failed to save');
            } else {
                const responseData = await response.json();
                console.log('La operación', responseData.operation);
                if (responseData.operation) {
                    setModalTitle("Mensaje");
                    setModalBody(responseData.mensaje);
                    setModalShow(true);
                    handleCleanFormSavePromo("FormSavePromo");
                    setFormSavePromo({ TIPO: "1" });
                } else {
                    setModalTitle("Mensaje");
                    setModalBody(responseData.mensaje);
                    setModalShow(true);
                }
            }
        } catch (error) {
            console.error('Error:', error);
        } finally {
            setLoading(false);
        }
    }

    async function updateAnuncio(request) {
        try {
            setLoading(true);
            const response = await fetch(`${config.URI_SERVICE}${config.METHOD_UPDATE_ANUNCIO}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(request)
            });

            if (!response.ok) {
                throw new Error('Failed to save');
            } else {
                const responseData = await response.json();
                if (responseData.operation) {
                    setModalTitle("Mensaje");
                    setModalBody(responseData.mensaje);
                    setModalShowEdMsg(true);
                    setModalShowEdit(false);
                } else {
                    setModalTitle("Mensaje");
                    setModalBody(responseData.mensaje);
                    setModalShow(true);
                }
            }
        } catch (error) {
            console.error('Error:', error);
        } finally {
            setLoading(false);
        }
    }

    async function deleteAnuncio(request) {
        try {
            setLoading(true);
            const response = await fetch(`${config.URI_SERVICE}${config.METHOD_DELETE_ANUNCIO}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(request)
            });

            if (!response.ok) {
                throw new Error('Failed to save');
            } else {
                const responseData = await response.json();
                if (responseData.operation) {
                    setModalTitle("Mensaje");
                    setModalBody(responseData.mensaje);
                    setModalShowEdMsg(true);
                } else {
                    setModalTitle("Mensaje");
                    setModalBody(responseData.mensaje);
                    setModalShow(true);
                }
            }
        } catch (error) {
            console.error('Error:', error);
        } finally {
            setLoading(false);
        }
    }

    const handleCleanFormSavePromo = (formId) => {
        const formulario = document.getElementById(formId);
        if (formId === "FormSavePromo") {
            setImgSavePromo("");
            setFormSavePromo({});
        }
        formulario.reset();
    }

    const onHideModalAlert = () => {
        window.location.reload();
        setModalShowViewImg(false)
    }

    const handleViewIMG = (index) => {
        setCursorDataPromo(index);
        setModalShowViewImg(true);
    }
    const handleEditPromo = (index) => {
        setImgEditPromo("");
        setCursorDataPromo(index);
        setModalShowEdit(true);
        setFormEditPromo(prevState => {
            const newState = { ...prevState };
            Object.entries(tablaDataPromos[index]).forEach(([key, value]) => {
                newState[key] = value;
            });
            return newState;
        });
    }

    const handleDeletePromo = (index) => {
        const sessionData = localStorage.getItem('session');
        if (sessionData) {
            const parsedSession = JSON.parse(sessionData);
            const request = { CVE_ANUNCIO: tablaDataPromos[index].CVE_ANUNCIO, CVE_USER_MOD: parsedSession.cve_usuario };
            if (Object.keys(request).length == 2) {
                deleteAnuncio(request);
            } else {
                setModalTitle("¡Cuidado!");
                setModalBody("Se requiere completar todos los campos del formulario.");
                setModalShow(true);
            }
        }

    }

    const handleChangeCallConsultPromoAll = async (event) => {
        event.preventDefault();
        if (Object.keys(formConsultPromoAll).length == 2) {
            try {
                setCursorDataPromo(0);
                setLoading(true);
                const response = await fetch(`${config.URI_SERVICE}${config.METHOD_FIND_ANUNCIO_ALL}`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(formConsultPromoAll)
                });
                if (!response.ok) {
                    throw new Error('Failed to save');
                } else {
                    const responseData = await response.json();
                    console.log('La operación', responseData.operation);
                    if (responseData.operation) {
                        setTablaDataPromos(responseData.mensaje);
                    } else {
                        setModalTitle("Mensaje");
                        setModalBody(responseData.mensaje);
                        setModalShow(true);
                    }
                }
            } catch (error) {
                console.error('Error:', error);
            } finally {
                setLoading(false);
            }
        } else {
            setModalTitle("¡Cuidado!");
            setModalBody("Se requieren todos los filtros");
            setModalShow(true);
        }

    }

    const handleChangeSendUpdatePromo = (event) => {
        event.preventDefault();
        const sessionData = localStorage.getItem('session');
        if (sessionData) {
            const parsedSession = JSON.parse(sessionData);
            const request = { ...formEditPromo, CVE_USER_MOD: parsedSession.cve_usuario, FILTER_TIPO: formConsultPromoAll.TIPO, FILTER_STATUS: formConsultPromoAll.STATUS };
            console.log(request);
            if (Object.keys(request).length == 14) {
                updateAnuncio(request);
            } else {
                setModalTitle("¡Cuidado!");
                setModalBody("Se requiere completar todos los campos del formulario.");
                setModalShow(true);
            }
        }
    }
    const handleChangeSendSavePromo = (event) => {
        event.preventDefault();
        const sessionData = localStorage.getItem('session');
        if (sessionData) {
            const parsedSession = JSON.parse(sessionData);
            const request = { ...formSavePromo, CVE_USUARIO: parsedSession.cve_usuario };
            if (Object.keys(request).length == 6) {
                saveAnuncio(request);
            } else {
                setModalTitle("¡Cuidado!");
                setModalBody("Se requiere completar todos los campos del formulario.");
                setModalShow(true);
            }
        }
    }
    const handleChangeConsultPromoAll = e => {
        setFormConsultPromoAll(prevState => ({ ...prevState, [e.target.name]: e.target.value }));
    }

    const compressIMG = async (img) => {
        try {
            // Configuración de compresión
            // Configuración de compresión
            const options = {
                useWebWorker: true, // Utilizar un web worker para mejorar el rendimiento (opcional)
                maxWidth: 300,      // Ancho máximo de la imagen después de la compresión
                maxHeight: 300,     // Altura máxima de la imagen después de la compresión
                crop: true          // Habilitar recorte de imagen
            };

            const compressedFile = await imageCompression(img, options);
            const reader = new FileReader();
            reader.onload = () => {
                const base64Image = reader.result;
                setFormSavePromo(prevState => ({ ...prevState, ["IMAGEN"]: base64Image }));
                setImgSavePromo(base64Image);
            };
            reader.readAsDataURL(compressedFile);
        } catch (error) {
            console.error('Error al comprimir la imagen:', error);
        }
    }   

    const handleChangeSavePromo = e => {
        if (e.target.type === 'file') {
            if (e.target.files.length > 0) {
                const file = e.target.files[0];
                if (file.size > 210 * 1024) { // 200 KB en bytes
                    setModalTitle("¡Cuidado!");
                    setModalBody("El tamaño del archivo excede los 200 KB permitidos.");
                    setModalShow(true);
                    return;
                }
                const reader = new FileReader();
                reader.onload = () => {
                    const base64Image = reader.result;
                    setFormSavePromo(prevState => ({ ...prevState, ["IMAGEN"]: base64Image }));
                    setImgSavePromo(base64Image);
                };
                reader.readAsDataURL(file);
            } else {
                setImgSavePromo("");
                setFormSavePromo(prevState => {
                    const { IMAGEN, ...rest } = prevState;
                    return { ...rest };
                });
            }
        } else {
            setFormSavePromo(prevState => ({ ...prevState, [e.target.name]: e.target.value }));
        }
    } 

    const handleChangeEditPromo = e => {
        if (e.target.type === 'file') {
            if (e.target.files.length > 0) {
                const file = e.target.files[0];
                const reader = new FileReader();
                reader.onload = () => {
                    const base64Image = reader.result;
                    setFormEditPromo(prevState => ({ ...prevState, ["IMAGEN"]: base64Image }));
                    setImgEditPromo(base64Image);
                };
                reader.readAsDataURL(file);
            } else {
                setImgEditPromo("");
                setFormEditPromo(prevState => {
                    const { IMAGEN, ...rest } = prevState;
                    return { ...rest };
                });
            }
        } else {
            setFormEditPromo(prevState => ({ ...prevState, [e.target.name]: e.target.value }));
        }
    }

    /*useEffect(() => {
        console.log(formEditPromo);
    }, [formEditPromo]);*/
    return (
        <>
            <div className="d-flex flex-row justify-content-center">
                <SidebarG51Admin>
                </SidebarG51Admin>
                <div className="container-fluid container-console">
                    <h3><label className="titulo-module-admin mt-2">Administrador de Anuncios</label></h3>
                    <div className="container-fluid light mb-3">
                        <Tabs
                            onSelect={handleSelect}
                            defaultActiveKey="alta"
                            id="uncontrolled-tab-example"
                            className="mb-3"
                        >
                            <Tab eventKey="alta" title="Alta" className={"d-flex flex-row justify-content-center " + (!tabsState[0] ? 'd-none' : '')}>
                                <Form id="FormSavePromo" className="form-promos-console mt-3">
                                    <InputGroup className="mb-3">
                                        <InputGroup.Text><MdOutlineViewModule /></InputGroup.Text>
                                        <Form.Select onChange={handleChangeSavePromo} name="TIPO" aria-label="Selecciona el tipo de promoción">
                                            <option value="1">Sección de promociones</option>
                                            <option value="2">Banner principal de promociones</option>
                                            <option value="3">Modal principal de promociones</option>
                                            <option value="4">Carousel principal</option>
                                        </Form.Select>
                                    </InputGroup>
                                    <InputGroup className="mb-3">
                                        <InputGroup.Text><MdTitle /></InputGroup.Text>
                                        <Form.Control
                                            name="TITULO"
                                            placeholder="Titulo"
                                            aria-label="TITULO"
                                            onChange={handleChangeSavePromo}
                                            aria-describedby="basic-addon1"
                                        />
                                    </InputGroup>
                                    <InputGroup className="mb-3">
                                        <InputGroup.Text><CiTextAlignCenter /></InputGroup.Text>
                                        <Form.Control
                                            onChange={handleChangeSavePromo}
                                            name="DESCRIPCION"
                                            placeholder="Descripción"
                                            aria-label="DESCRIPCION"
                                            aria-describedby="basic-addon1"
                                        />
                                    </InputGroup>
                                    <InputGroup className="mb-3">
                                        <InputGroup.Text><GoLaw /></InputGroup.Text>
                                        <Form.Control
                                            onChange={handleChangeSavePromo}
                                            name="TERMINOS_CONDICIONES"
                                            placeholder="Terminos y Condiciones"
                                            aria-label="TERMINOS_CONDICIONES"
                                            aria-describedby="basic-addon1"
                                        />
                                    </InputGroup>
                                    <Row className="d-flex flex-row justify-content-center align-items-center">
                                        <Col xs={6} md={6}>
                                            <Form.Group controlId="formFile" className="mb-3">
                                                <Form.Label className="label-form-console">Selecciona imagen</Form.Label>
                                                <Form.Control type="file" accept="image/*" onChange={handleChangeSavePromo} />
                                            </Form.Group>
                                        </Col>
                                        <Col xs={6} md={6}>
                                            <Image src={imgSavePromo} thumbnail width={150} />
                                        </Col>
                                    </Row>
                                    <div className="float-end mb-3">
                                        <Button variant="outline-danger" onClick={() => handleCleanFormSavePromo('FormSavePromo')}>Limpiar</Button>{'  '}
                                        {!loading ? (
                                            <Button variant="outline-info" onClick={handleChangeSendSavePromo}>Guardar promoción</Button>
                                        ) : (
                                            <Button variant="outline-info" disabled>Guardando promoción...</Button>
                                        )}
                                    </div>
                                </Form>
                            </Tab>
                            <Tab eventKey="consulta" title="Consulta y Actualización" className={"d-flex flex-row justify-content-center " + (!tabsState[1] ? 'd-none' : '')}>
                                <Container>
                                    <Row>
                                        <Form id="FormConsultPromo" className="form-promos-console mt-3">
                                            <Row>
                                                <Col xs={4} md={4}>
                                                    <InputGroup className="mb-3">
                                                        <InputGroup.Text><MdOutlineViewModule /></InputGroup.Text>
                                                        <Form.Select onChange={handleChangeConsultPromoAll} name="TIPO" aria-label="Selecciona el tipo de promoción">
                                                            <option value="1">Sección de promociones</option>
                                                            <option value="2">Banner principal de promociones</option>
                                                            <option value="3">Modal principal de promociones</option>
                                                            <option value="4">Carousel principal</option>
                                                        </Form.Select>
                                                    </InputGroup>
                                                </Col>
                                                <Col xs={4} md={4}>
                                                    <InputGroup className="mb-3">
                                                        <InputGroup.Text><GrStatusUnknown /></InputGroup.Text>
                                                        <Form.Select onChange={handleChangeConsultPromoAll} name="STATUS" aria-label="Selecciona el estatus">
                                                            <option value="1">ACTIVO</option>
                                                            <option value="2">INACTIVO</option>
                                                        </Form.Select>
                                                    </InputGroup>
                                                </Col>
                                                <Col xs={4} md={4}>
                                                    {!loading ? (
                                                        <Button variant="outline-info" onClick={handleChangeCallConsultPromoAll}>Consultar</Button>
                                                    ) : (
                                                        <Button variant="outline-info" disabled>Consultando...</Button>
                                                    )}
                                                </Col>
                                            </Row>
                                        </Form>
                                    </Row>
                                    <Row className="mb-3">
                                        <Col>
                                            <Table id="tabPromos" responsive striped bordered hover>
                                                <thead>
                                                    <tr>
                                                        <th>TITULO</th>
                                                        <th>DESCRIPCIÓN</th>
                                                        <th>T & C</th>
                                                        <th>IMAGEN</th>
                                                        <th>STATUS</th>
                                                        <th>TIPO</th>
                                                        <th>FECHA CREACIÓN</th>
                                                        <th></th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {tablaDataPromos.map((fila, index) => (
                                                        <tr key={index}>
                                                            <td>{fila.TITULO}</td>
                                                            <td>{fila.DESCRIPCION}</td>
                                                            <td>{fila.TERMINOS_CONDICIONES}</td>
                                                            <td><Button onClick={() => handleViewIMG(index)} variant="primary">Ver</Button></td>
                                                            <td>{fila.STATUS == '1' ? 'ACTIVO' : 'INACTIVO'}</td>
                                                            <td>{fila.TIPO}</td>
                                                            <td>{fila.FECHA_CREACION}</td>
                                                            <td><Button onClick={() => handleEditPromo(index)} variant="primary"><FaRegEdit /></Button></td>
                                                            <td><Button onClick={() => handleDeletePromo(index)} variant="danger"><FaRegTrashAlt /></Button></td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </Table>
                                        </Col>
                                    </Row>
                                </Container>
                            </Tab>
                        </Tabs>
                    </div>
                </div>
            </div>

            <Modal
                size="md"
                show={modalShow}
                onHide={() => setModalShow(false)}
                aria-labelledby="example-modal-sizes-title-lg">
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">
                        {modalTitle}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>{modalBody}</Modal.Body>
            </Modal>

            <Modal
                size="md"
                show={modalShowEdMsg}
                onHide={() => onHideModalAlert()}
                aria-labelledby="example-modal-sizes-title-lg">
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">
                        {modalTitle}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>{modalBody}</Modal.Body>
            </Modal>

            <Modal
                size="lg"
                show={modalShowEdit}
                onHide={() => setModalShowEdit(false)}
                aria-labelledby="example-modal-sizes-title-lg">
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">
                        {tablaDataPromos.length > 0 && (
                            <h1>{tablaDataPromos[cursorDataPromo].TITULO}</h1>
                        )}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container className="d-flex flex-row justify-content-center">
                        {tablaDataPromos.length > 0 && (
                            <Form id="FormEditPromo" className="form-promos-console mt-3">
                                <InputGroup className="mb-3">
                                    <InputGroup.Text><MdOutlineViewModule /></InputGroup.Text>
                                    <Form.Select defaultValue={tablaDataPromos[cursorDataPromo].TIPO} onChange={handleChangeEditPromo} name="TIPO" aria-label="Selecciona el tipo de promoción">
                                        <option value="1">Sección de promociones</option>
                                        <option value="2">Banner principal de promociones</option>
                                        <option value="3">Modal principal de promociones</option>
                                        <option value="4">Carousel principal</option>
                                    </Form.Select>
                                </InputGroup>
                                <InputGroup className="mb-3">
                                    <InputGroup.Text><MdTitle /></InputGroup.Text>
                                    <Form.Control
                                        defaultValue={tablaDataPromos[cursorDataPromo].TITULO}
                                        name="TITULO"
                                        placeholder="Titulo"
                                        aria-label="TITULO"
                                        onChange={handleChangeEditPromo}
                                        aria-describedby="basic-addon1"
                                    />
                                </InputGroup>
                                <InputGroup className="mb-3">
                                    <InputGroup.Text><CiTextAlignCenter /></InputGroup.Text>
                                    <Form.Control
                                        defaultValue={tablaDataPromos[cursorDataPromo].DESCRIPCION}
                                        onChange={handleChangeEditPromo}
                                        name="DESCRIPCION"
                                        placeholder="Descripción"
                                        aria-label="DESCRIPCION"
                                        aria-describedby="basic-addon1"
                                    />
                                </InputGroup>
                                <InputGroup className="mb-3">
                                    <InputGroup.Text><GoLaw /></InputGroup.Text>
                                    <Form.Control
                                        defaultValue={tablaDataPromos[cursorDataPromo].TERMINOS_CONDICIONES}
                                        onChange={handleChangeEditPromo}
                                        name="TERMINOS_CONDICIONES"
                                        placeholder="Terminos y Condiciones"
                                        aria-label="TERMINOS_CONDICIONES"
                                        aria-describedby="basic-addon1"
                                    />
                                </InputGroup>
                                <InputGroup className="mb-3">
                                    <InputGroup.Text><GrStatusUnknown /></InputGroup.Text>
                                    <Form.Select defaultValue={tablaDataPromos[cursorDataPromo].STATUS} onChange={handleChangeEditPromo} name="STATUS" aria-label="Selecciona el estatus">
                                        <option value="1">ACTIVO</option>
                                        <option value="2">INACTIVO</option>
                                    </Form.Select>
                                </InputGroup>
                                <Row className="d-flex flex-row justify-content-center align-items-center">
                                    <Col xs={6} md={6}>
                                        <Form.Group controlId="formFile" className="mb-3">
                                            <Form.Label className="label-form-console">Selecciona imagen</Form.Label>
                                            <Form.Control type="file" accept="image/*" onChange={handleChangeEditPromo} />
                                        </Form.Group>
                                    </Col>
                                    <Col xs={6} md={6}>
                                        <Image
                                            src={imgEditPromo ? imgEditPromo : tablaDataPromos[cursorDataPromo].IMAGEN}
                                            thumbnail
                                            width={150}
                                        />
                                    </Col>
                                </Row>
                                <div className="float-end mb-3">
                                    {!loading ? (
                                        <Button variant="outline-info" onClick={handleChangeSendUpdatePromo}>Guardar promoción</Button>
                                    ) : (
                                        <Button variant="outline-info" disabled>Guardando promoción...</Button>
                                    )}
                                </div>
                            </Form>
                        )}
                    </Container>
                </Modal.Body>
            </Modal>

            <Modal
                size="md"
                show={modalShowViewImg}
                onHide={() => setModalShowViewImg(false)}
                aria-labelledby="example-modal-sizes-title-lg">
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">
                        <h1>Visor de imagen</h1>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container className="d-flex flex-row justify-content-center">
                        {tablaDataPromos.length > 0 && (
                            <Image src={tablaDataPromos[cursorDataPromo].IMAGEN} rounded width={350} />
                        )}
                    </Container>
                </Modal.Body>
            </Modal>
        </>
    );
}