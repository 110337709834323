import React, { useState } from 'react';

export default function AvisoPrivacidad() {
    return (
        <>
            <div className="container">
                <h1 className='mt-2 title_AP'>Aviso de Privacidad</h1>
                <div className="mb-4 container d-flex flex-row justify-content-center align-items-center">
                    <div className='containerAP d-flex flex-row justify-content-center align-items-center'>
                        <div className="post-it-note">
                            <p className='p_ap'>De acuerdo a Io Previsto en el Art. 15 y demás aplicables en la “Ley Federal de Protección de Datos Personales en posesión de particulares”
                                , declaramos ser una empresa
                                legalmente constituida de conformidad con las leyes mexicanas y como responsable del tratamiento de sus datos personales, hace de su conocimiento que la información
                                de nuestros clientes es tratada de forma estrictamente confidencial por Io que al proporcionar sus datos personales, tales como:</p>
                            <ul className='lista_ap'>
                                <li>Nombre Completo</li>
                                <li>Dirección</li>
                                <li>Registro federal de contribuyentes</li>
                                <li>Teléfonos de hogar, oficina y móviles</li>
                                <li>Correo electrónico</li>
                            </ul>
                            <p className='p_ap'>Estos serán utilizados única y exclusivamente para los siguientes fines:</p>
                            <ul className='lista_ap'>
                                <li>Campañas de Publicidad</li>
                                <li>Campañas de Fidelidad</li>
                                <li>información y Prestación de Servicios</li>
                                <li>Actualización de la Base de Datos</li>
                                <li>Evaluación de nuestros estándares de calidad en el servicio que le brindamos</li>
                                <li>Cualquier finalidad análoga o compatible con las anteriores</li>
                                <li>Compartidos con proveedores de bienes y servicios que se consideren de su interés y beneficio</li>
                            </ul>
                            <p className='p_ap'>Para prevenir el acceso no autorizado a sus datos personales y con el fin de asegurar que la información sea utilizada para los fines establecidos en este aviso de
                                privacidad, hemos establecido diversos procedimientos con la finalidad de evitar el uso o divulgación no autorizados de sus datos, permitiéndonos tratarlos debidamente</p>
                            <p className='p_ap'>Todos sus datos personales son tratados de acuerdo a la legislación aplicable y vigente en el país que de conformidad con el articulo 22 de la Ley, usted tiene en todo
                                momento los derechos de acceder, rectificar, cancelar u oponerse al tratamiento que le damos a sus datos personales o bien revocar el consentimiento que para tal fin
                                se nos haya otorgado, a través del procedimiento que hemos implementado; derecho que podrá hacer valer a través del Área de Privacidad encargada de la seguridad de
                                datos personales por medio de su correo electrónico: administracion@garage51.mx</p>
                            <p className='p_ap'>A través de estos canales usted podrá actualizar sus datos y especificar el medio por el cual desea recibir información, ya que en caso de no contar con esta
                                especificación de su parte, GARAGE 51 establecerá libremente el canal que considere pertinente para enviarle información.</p>
                            <p className='p_ap'>Este aviso de privacidad podrá ser modificado por GARAGE 51, dichas modificaciones serán oportunamente informadas a través de correo electrónico, teléfono, o
                                cualquier otro medio de comunicación que G51 determine para tal efecto.</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}