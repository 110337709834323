export default function CardG51(props) {
    return (
        <div className="card_p p-2">
            <div >
                <img src={props.url} className="img-fluid rounded-start" alt="..." />
            </div>
            <div className=" info">
                <div className="">
                    <h1 className="card-title text-center mb-md-5 mb-1">Promoción {props.dia}</h1>
                    <h4 className="card-text">
                        {props.titulo}
                    </h4>
                    <h6>
                        {props.tyc}
                    </h6>
                </div>
            </div>
        </div>
    );
}