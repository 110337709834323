import React, { useState } from 'react';
import {FaUserAlt,FaBars} from "react-icons/fa";
import { MdStorefront } from "react-icons/md";
import { IoMdPricetags } from "react-icons/io";
import { NavLink } from 'react-router-dom';


const SidebarG51Admin = ({ children }) => {
    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => setIsOpen(!isOpen);
    const menuItem = [
        {
            path: "/admin_promos",
            name: "Anuncios",
            icon: <IoMdPricetags />
        },
        {
            path: "/admin_sucursales",
            name: "Sucursales",
            icon: <MdStorefront />
        },
        {
            path: "/admin_usuarios_cosole",
            name: "Usuarios",
            icon: <FaUserAlt />
        }
    ]
    return (
        <>
            <div style={{ width: isOpen ? "200px" : "50px",position:'fixed',left:'0'}} className="sidebar">
                <div className="top_section">
                    <h1 style={{ display: isOpen ? "block" : "none" }} className="logo"><a className='home-admin-console' href="/cosole_admin"> MENÚ</a></h1>
                    <div style={{ marginLeft: isOpen ? "50px" : "0px" }} className="bars">
                        <FaBars onClick={toggle} />
                    </div>
                </div>
                {
                    menuItem.map((item, index) => (
                        <NavLink to={item.path} key={index} className="link">
                            <div className="icon">{item.icon}</div>
                            <div style={{ display: isOpen ? "block" : "none" }} className="link_text">{item.name}</div>
                        </NavLink>
                    ))
                }
            </div>
        </>
    );
};

export default SidebarG51Admin;