import { useEffect, useState } from "react";
import CardG51 from "./CardG51";
import config from '../config.json'

export default function Promociones() {
    const [promos, setPromos] = useState([]);

    useEffect(() => {
        const callServicePromociones = async () => {
            try {
                const response = await fetch(`${config.URI_SERVICE}${config.METHOD_GET_ANUNCIOS_TIPO}`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({ TIPO: "1" })
                });

                if (!response.ok) {
                    throw new Error('Failed to save');
                } else {
                    const responseData = await response.json();
                    if (responseData.operation) {
                        const newPromos = responseData.mensaje.map(element => ({
                            "key": element.CVE_ANUNCIO,
                            "url": element.IMAGEN,
                            "dia": element.TITULO,
                            "titulo": element.DESCRIPCION,
                            "tyc": element.TERMINOS_CONDICIONES
                        }));
                        setPromos(newPromos);
                    }
                }
            } catch (error) {
                console.error('Error:', error);
            }
        };

        callServicePromociones();
    }, []); // <-- Array vacío para que el efecto se ejecute solo una vez al montar el componente

    return (
        <div className="container">
            {promos.length === 0 ? (
                <div className="row row-cols-md-2 contentPromos">
                    <h1 className="title_cargando">Cargando...</h1>
                </div>
            ) : (
                <div className="row row-cols-md-2 contentPromos">
                    {promos.map((e) => (
                        <CardG51 key={e.key} url={e.url} dia={e.dia} titulo={e.titulo} tyc={e.tyc} />
                    ))}
                </div>
            )}
        </div>
    );
}