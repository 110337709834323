import React, { useState } from 'react';

export default function TerminosCondiciones() {
    return (
        <>
            <div className="container">
                <h1 className='mt-2 title_TC'>Terminos y Condiciones</h1>
                <div className="mb-4 container d-flex flex-row justify-content-center align-items-center">
                    <div className='containerTC d-flex flex-row justify-content-center align-items-center'>
                        <div className="post-it-note">
                            <ul className='lista_tc'>
                                <li>LUNES. Desde Enero 2024, todos los dias Lunes seria dia de descanso para nuestros colaboradores ¡Lamentamos
                                    los inconvenientes!</li>
                                <li>MARTES. Buffet x $179. Se puede elegir entre alitas o boneless, solo 1 salsa, promocion personal, no aplica en
                                    servicio a domicilio; solo sucursal, valido de 3 a 9:30 pm</li>
                                <li>MIERCOLES. Dia de G51, todos los articulos marcados con el logo tienen descuento de $51 pesos . Valido en
                                    sucursal y a domicilio de 3 a 9:30 pm</li>
                                <li>JUEVES. 20 piezas x $189. Se puede elegir entre alitas o boneless o combinar, 2 salsas incluidas. Valido en sucursal
                                    o a domicilio de 2 a 10:30 pm</li>
                                <li>SABADO. 5 alitas GRATIS!! En la compra de una orden de 15 alitas, 2 salsas incluidas. Valido solo en alitas, en
                                    sucursal y a domicilio. De 3 a 9:30 pm</li>
                                <li>DOMINGO. ¡Todo lo que puedas comer x $239! Disfruta de todos los platillos de la carta. Valido unicamente en
                                    comida, no bebidas, promocion personal de 3 a 9:30 pm</li>
                                <li>TODAS NUESTRAS PROMOCIONES SON VALIDAS HASTA AGOTAR EXISTENCIAS</li>
                                <li>PROHIBIDA LA VENTA DE ALCOHOL A MENORES DE EDAD</li>
                                <li>TODAS NUESTRAS BEBIDAS ALCOHOLICAS DEBEN IR ACOMPAÑADAS POR UN CONSUMO MINIMO DE $90 PESOS
                                    EN ALIMENTOS</li>
                                <li>EL SERVICIO A DOMICILIO SOLO APLICA HASTA LAS 10:30 PM DE MARTES A SABADO Y A LAS 9:30 DOMIINGO
                                    NO APLICA PROMO SOBRE PROMO</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}