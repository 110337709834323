import { faEnvelope } from "@fortawesome/free-regular-svg-icons";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Fragment, useEffect, useState } from "react";

export default function Sucursales() {
  const [sucursales, setSucursales] = useState([]);
  useEffect(() => {
    const getItemsSucursal = async () => {
      let res = await fetch("helpers/Sucursales.json"),
        json = await res.json();
      let newSucursales = json.SUCURSALES.map((el, i) => {
        //console.log("NOMBRE", el.NOMBRE);
        return {
          CVE: el.CVE,
          IMG: "./assets/img/sucursales/" + el.IMG,
          NOMBRE: el.NOMBRE,
          DOMICILIO: el.DOMICILIO,
          TELEFONOS: el.TELEFONOS,
          EMAILS: el.EMAILS,
          HORARIOS: el.HORARIOS,
          MAPS: el.MAPS,
          APPS: el.APPS,
        };
      });
      setSucursales(newSucursales);
      //console.log("ELEMENTOS", newSucursales);
    };
    getItemsSucursal();
  }, []);

  return (
    <div className="container-fluid mb-4">
      <div className="row justify-content-center contentSuc">
        {
          sucursales.map((e) => (
            <div className="col-12 col-md-10 ">
              <div className="card m-0 contentCard mt-3" >
                <div className="row ">
                  <div className="col-md-8 card-body p-0">
                    <div className="info-sucursal">
                      <h5 className="card-title card-titleSuc text-center">{e.NOMBRE}</h5>
                      <div className="textInfo w-100">

                        <div className="mb-0">
                          <h6>DIRECCIÓN</h6>
                          {`${e.DOMICILIO.CALLE} #${e.DOMICILIO.NUMERO} ${e.DOMICILIO.COLONIA}, ${e.DOMICILIO.DELAGACION} 
                             ${e.DOMICILIO.CP},  ${e.DOMICILIO.CIUDAD}`}
                        </div>
                        <div className="card-text sucHorarios pt-1 datosSuc">
                          <h6>HORARIOS</h6>
                          <table id={e.CVE} key={'Horario' + e.CVE} className="tab_horarios">
                            <tbody>
                              {e.HORARIOS.map((el, key) => (
                                <tr>
                                  <td>{el.DIA}</td>
                                  <td className="text-center">
                                    {el.OPEN !== "Cerrado" ? (
                                      <>
                                        {el.OPEN} a {el.CLOSE}
                                      </>
                                    ) : (
                                      <>
                                        {el.OPEN}
                                      </>
                                    )}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                        <div>
                          <h6>CONTACTO</h6>
                          {e.TELEFONOS.map((el, key) => (
                            <label key={key} className="text-tel2">
                              <FontAwesomeIcon icon={faPhone}></FontAwesomeIcon>{" "}{el}
                            </label>
                          ))}
                          <br />
                          {e.EMAILS.map((el, key) => (
                            <Fragment>

                              <label key={key} className="text-tel2">
                                <FontAwesomeIcon icon={faEnvelope}></FontAwesomeIcon>{" "}{el}
                              </label><br />
                            </Fragment>
                          ))}
                        </div>
                        <div>
                          <h6>APPS</h6>
                          {e.APPS.map((el, key) => (
                            <a
                              key={el.key}
                              href={el.URL}
                              rel="noreferrer"
                              target="_blank"
                            >
                              <img className="icon-apps " src={el.ICON} alt={el.NAME} />
                            </a>
                          ))}
                        </div>
                      </div>
                      <img src={e.IMG} className="w-100"></img>
                    </div>

                  </div>

                  <div className="col-md-4 p-0">
                    <iframe src={e.MAPS} className="w-100 h-100"></iframe>
                  </div>
                </div>
              </div>
            </div>

          ))
        }
      </div>
    </div>
  );
}