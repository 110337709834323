import { faFacebook, faInstagram, faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { faRegistered } from "@fortawesome/free-regular-svg-icons";
import { faBook, faChildReaching, faCommentDots, faHeading, faLock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Fragment } from "react";
import logo from '../Assets/img/1.svg';

export default function Footer() {
    return (
        <div className="container-fluid  footer bottom">
            <div className="row justify-content-center">
                <div className="col-10 col-md-4 text-center">
                    <img src={logo} className="logo"></img>
                </div>
            </div>
            <div className="row justify-content-center">
                <div className="col-10 col-md-3 ">
                    <ul className="d-flex flex-column align-items-center align-items-md-start">
                        <li className="flicker1 neon linkf d-flex me-1 ">
                            <a className="align-content-center d-flex justify-content-center linkf"
                                href="https://www.facebook.com/Garage51wb"
                                target="_blank">
                            <i><FontAwesomeIcon icon={faFacebook} /></i> Facebook
                            </a>
                        </li>
                        <li className="flicker1 neon linkf d-flex me-2">
                            <a className="align-content-center d-flex justify-content-center linkf"
                                href="https://wa.me/5561277866?text=¡Hola!%20Podrías%20darme%20mas%20informacion%20de"
                                target="_blank">
                                <i><FontAwesomeIcon icon={faWhatsapp} /></i> Whatsapp
                            </a>
                        </li>
                        <li className="flicker1 neon linkf d-flex me-2">
                            <a className="align-content-center d-flex justify-content-center linkf"
                                href="https://www.instagram.com/garage51wb/"
                                target="_blank">
                                
                                <i><FontAwesomeIcon icon={faInstagram} /></i> Instagram
                            </a>
                        </li>
                    </ul>
                </div>
                <div className="col-10 col-md-3 text-center text-md-start">
                    <i className="flicker1 neon linkf "><FontAwesomeIcon icon={faHeading} /><a href="/historia"> Historia</a><br /></i>
                    <i className="flicker1 neon linkf "><FontAwesomeIcon icon={faChildReaching} /><a href="/quienes_somos"> Quienes somos</a></i>
                </div>
                <div className="col-10 col-md-3 text-center text-md-start">
                    <i className="flicker1 neon linkf "><FontAwesomeIcon icon={faBook} /><a href="/terminos_condiciones"> Terminos y condiciones</a><br /></i>
                    <i className="flicker1 neon linkf "><FontAwesomeIcon icon={faLock} /><a href="/aviso_privacidad"> Aviso de privacidad</a><br /></i>
                    <i className="flicker1 neon linkf "><FontAwesomeIcon icon={faCommentDots} /><a> Contacto</a></i>
                </div>
            </div>
            <div className="row flex-column align-items-center row-cols-2 name mt-3">
                <h4 className="text-center">GARAGE 51 <FontAwesomeIcon icon={faRegistered} className="registered" /></h4>
                <p className="text-center">DEVELOPED BY X-DEVELOPERS</p>
            </div>
        </div>
    );
}