import React, { useState } from 'react';
import img_g51 from "../Assets/img/galeria/307894536_113209374868732_2358394119508060_n.jpg"
import { Image } from 'react-bootstrap';

export default function Historia() {
    return (
        <>
            <div className="container">
                <h1 className='mt-2 title_AP'>Algo sobre GARAGE 51</h1>
                <div className="mb-4 container d-flex flex-row justify-content-center align-items-center">
                    <div className='containerAP d-flex flex-row justify-content-center align-items-center'>
                        <div className="post-it-note">
                            <p className='p_ap'>Nuestra principal motivacion para crear Garage 51 fue
                                la de pasar un buen rato con tu familia y amigos, en un
                                ambiente agradable; con un concepto de Garage
                                (motos, autos, rutas y ROCK). Ademas de ofrecer la
                                mayor calidad y sabor en el mundo de los restaurantes
                                sport - casual..
                            </p>
                            <p className='p_ap'>Nos seguimos renovando para vivir de la mano de un
                                solo objetivo; crear las mejores experiencias e
                                increibles encuentros.</p>
                            <p className='p_ap'>Simplemente si amas las alitas, los spots tematicos y el
                                rock, estas en lugar indicado!!</p>
                            <div className='container-fluid d-flex flex-row justify-content-center'>
                                <Image src={img_g51} roundedCircle width={500} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}