import { faFacebook, faInstagram, faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Container, Nav, Navbar, Offcanvas } from "react-bootstrap";
import { Link } from "react-router-dom";
import logo from '../Assets/img/1.svg';
import { useEffect } from "react";
export default function NavbarG51() {
  let handleCollapse = (e) => {
    //console.log("handleCollapse")
    console.log(e.target.classList)

    document.querySelector(".active").classList.remove("active");
    e.target.classList.add("active")
    let btn = document.getElementsByClassName("navbar-toggler")[0];
    // 
    //console.log(btn.innerHTML);
    btn.click();
  };
  return (
    <>
      <Navbar collapseOnSelect expand="md" bg="dark" variant="dark" className="nav p-0 m-0">
        <Container>
          <Navbar.Brand href="/"> <img src={logo} className="logo"></img></Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav" className=" justify-content-center">
            <Nav className="me-auto " >
              <Link onClick={handleCollapse} to="/" className="nav-link active">HOME</Link>
              <Link onClick={handleCollapse} className="nav-link" to="/menu">MENÚ</Link>
              <Link onClick={handleCollapse} className="nav-link" to="/Promociones">PROMOCIONES</Link>
              <Link onClick={handleCollapse} className="nav-link" to="/Sucursales">SUCURSALES</Link>
            </Nav>
            <Nav >
              <ul className="d-flex justify-content-center align-items-end">
                <li className="colorFace d-flex justify-content-center align-items-center">
                  <span><a className="align-content-center d-flex justify-content-center"
                   href="https://www.facebook.com/Garage51wb" target="_blank"
                  ><FontAwesomeIcon icon={faFacebook} /></a></span>
                </li>
                <li className="colorWhats d-flex justify-content-center align-items-center">
                  <span><a className="align-content-center d-flex justify-content-center"
                    href="https://wa.me/5561277866?text=¡Hola!%20Podrías%20darme%20mas%20informacion%20de"
                    target="_blank">
                    <FontAwesomeIcon icon={faWhatsapp} /></a></span>
                </li>
                <li className="colorInsta d-flex justify-content-center align-items-center">
                  <span><a className="align-content-center d-flex justify-content-center"
                  href="https://www.instagram.com/garage51wb/" target="_blank"
                  ><FontAwesomeIcon icon={faInstagram} /></a></span>
                </li>
              </ul>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
}