import { Fragment } from "react";
import { faFire } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
export default function Picometro() {
    return (
        <Fragment>
            <h1>Nuestras salsas</h1>
            <div className="row  text-white justify-content-center">
                <div className="col-12 col-md-5">
                    <div className="row row-cols-2 justify-content-between align-items-center">
                        <p >PARMESANO</p>
                        <i >
                            <FontAwesomeIcon icon={faFire} />
                        </i>
                        <p>LEMON PEPPER</p>
                        <i >
                            <FontAwesomeIcon icon={faFire} />
                        </i>
                        <p>MANGO BBQ</p>
                        <i >
                            <FontAwesomeIcon icon={faFire} />
                        </i>
                        <p>BBQ</p>
                        <i >
                            <FontAwesomeIcon icon={faFire} />
                        </i>
                        <p>PETROLERA</p>
                        <i >
                            <FontAwesomeIcon icon={faFire} />
                        </i>
                        <p>TAMARINDO</p>
                        <i >
                            <FontAwesomeIcon icon={faFire} />
                        </i>
                        <p>PELON PELORICO</p>
                        <i >
                            <FontAwesomeIcon icon={faFire} />
                        </i>
                        <p>PICA FRESA</p>
                        <i >
                            <FontAwesomeIcon icon={faFire} />
                        </i>
                    </div>
                </div>
                <div className="col-12 col-md-7">
                    <div className="row row-cols-2 justify-content-between align-items-center">

                        <p>HONEY CHIPOTLE</p>
                        <i >
                            <FontAwesomeIcon icon={faFire} />
                            <FontAwesomeIcon icon={faFire} />
                        </i>
                        <p>BUFALO</p>
                        <i >
                            <FontAwesomeIcon icon={faFire} />
                            <FontAwesomeIcon icon={faFire} />
                        </i>
                        <p>PETROLERA HOT</p>
                        <i >
                            <FontAwesomeIcon icon={faFire} />
                            <FontAwesomeIcon icon={faFire} />
                            <FontAwesomeIcon icon={faFire} />
                        </i>
                        <p>FLAMIN HOT</p>
                        <i >
                            <FontAwesomeIcon icon={faFire} />
                            <FontAwesomeIcon icon={faFire} />
                            <FontAwesomeIcon icon={faFire} />
                        </i>

                        <p>CAJUN</p>
                        <i >
                            <FontAwesomeIcon icon={faFire} />
                            <FontAwesomeIcon icon={faFire} />
                            <FontAwesomeIcon icon={faFire} />
                        </i>

                        <p>MANGO HABANERO</p>
                        <i >
                            <FontAwesomeIcon icon={faFire} />
                            <FontAwesomeIcon icon={faFire} />
                            <FontAwesomeIcon icon={faFire} />
                            <FontAwesomeIcon icon={faFire} />
                        </i>

                        <p>HABANERO</p>
                        <i >
                            <FontAwesomeIcon icon={faFire} />
                            <FontAwesomeIcon icon={faFire} />
                            <FontAwesomeIcon icon={faFire} />
                            <FontAwesomeIcon icon={faFire} />
                            <FontAwesomeIcon icon={faFire} />
                        </i>
                        <p>CHILTEPIN</p>
                        <i >
                            <FontAwesomeIcon icon={faFire} />
                            <FontAwesomeIcon icon={faFire} />
                            <FontAwesomeIcon icon={faFire} />
                            <FontAwesomeIcon icon={faFire} />
                            <FontAwesomeIcon icon={faFire} />
                            <FontAwesomeIcon icon={faFire} />
                        </i>

                    </div>
                </div>

            </div>
        </Fragment>
    );
}